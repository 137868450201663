import { Player } from "@lottiefiles/react-lottie-player";
import moneyBagAnimation from "../assets/moneyBag.json";

const Loader = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center bg-black bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <Player src={moneyBagAnimation} autoplay loop className="w-72 h-72" />
    </div>
  );
};

export default Loader;
