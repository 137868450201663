import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import { BookStack, CloudDownload, CreditCard, Discord, Group, MediaVideoList, RemoveUser, SendMail } from "iconoir-react";

const Membership = ({ authState, authDispatch }) => {
  const [name, setName] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const handleMouseMove = (e) => {
      const cards = document.getElementById("cards");
      const cardElements = cards.getElementsByClassName("card");

      for (const card of cardElements) {
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        card.style.setProperty("--mouse-x", `${x}px`);
        card.style.setProperty("--mouse-y", `${y}px`);
      }
    };

    document.getElementById("cards").addEventListener("mousemove", handleMouseMove);

    return () => {
      // document.getElementById("cards").removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    navigate("/signIn");
  };

  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start cursor-pointer" onClick={() => navigate("/")}>
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <div className="flex flex-row justify-end items-center gap-4">
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => logout()}
          >
            Sign Out
          </p>
        </div>
      </div>
      <div className="w-full max-w-3xl flex flex-col justify-start items-center rounded-xl px-8 py-14 gap-5">
        <h3 className="text-lg font-light">Manage your Membership</h3>
        <div id="cards" className="justify-center">
          <div className="card" onClick={() => navigate("/paymentMethods")}>
            <div className="card-content">
              <div className="card-image flex justify-center items-center">
                <CreditCard className="h-16 w-auto mix-blend-overlay" />
              </div>
              <div className="card-info-wrapper">
                <div className="card-info">
                  <div className="card-info-title">
                    <h3 className="text-lg">Payment Method</h3>
                    <h4 className="text-xs">Update the payment method attached to your membership</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card" onClick={() => navigate("/membershipManagement")}>
            <div className="card-content">
              <div className="card-image">
                <RemoveUser className="h-16 w-auto mix-blend-overlay" />
              </div>
              <div className="card-info-wrapper">
                <div className="card-info">
                  <div className="card-info-title">
                    <h3 className="text-lg">Membership</h3>
                    <h4 className="text-xs">Review, upgrade or cancel your membership</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-xs mt-4 text-purple-700/50 hover:text-purple-700 duration-150 ease-in-out cursor-pointer" onClick={() => navigate("/")}>
          Go Back
        </p>
      </div>
      <Footer />
    </div>
  );
};
export default Membership;
