import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../views/home/dashboard";
import Middleware from "../views/onboarding/middleware";
import Payment from "../views/onboarding/payment";
import Discord from "../views/onboarding/discord";
import ContactUs from "../views/home/contact";
import Membership from "../views/home/membership";
import Library from "../views/home/library";
import Reading from "../views/home/reading";
import Content from "../views/home/content";
import PaymentMethods from "../views/home/payments";
import ManageMembership from "../views/home/manageMembership";

const CoreLayout = ({ authState, authDispatch }) => {
  return authState.isAuth ? (
    authState.user.onboardingStage === 3 ? (
      <Routes>
        <Route path="/" element={<Dashboard authState={authState} authDispatch={authDispatch} />} />
        <Route path="/contact" element={<ContactUs authState={authState} authDispatch={authDispatch} />} />
        <Route path="/membership" element={<Membership authState={authState} authDispatch={authDispatch} />} />
        <Route path="/library" element={<Library authState={authState} authDispatch={authDispatch} />} />
        <Route path="/reading" element={<Reading authState={authState} authDispatch={authDispatch} />} />
        <Route path="/content" element={<Content authState={authState} authDispatch={authDispatch} />} />
        <Route path="/paymentMethods" element={<PaymentMethods authState={authState} authDispatch={authDispatch} />} />
        <Route path="/membershipManagement" element={<ManageMembership authState={authState} authDispatch={authDispatch} />} />
        <Route path="*" element={<Dashboard authState={authState} authDispatch={authDispatch} />} />
      </Routes>
    ) : (
      <Routes>
        <Route path="*" element={<Middleware authState={authState} authDispatch={authDispatch} />} />
        <Route path="payment" element={<Payment authState={authState} authDispatch={authDispatch} />} />
        <Route path="discordAuth" element={<Discord authState={authState} authDispatch={authDispatch} />} />
      </Routes>
    )
  ) : (
    <Navigate to="/signIn" />
  );
};

export default CoreLayout;
