import axiosInstance from "./axios";

export const VerifyDiscordAuth = (data) => {
  return axiosInstance.post("/api/verifyDiscordAuth", data);
};

export const SubmitContactForm = (data) => {
  return axiosInstance.post("/api/contact", data);
};

export const AddDiscordRole = () => {
  return axiosInstance.get("/api/role");
};
