import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Footer from "../../components/footer";
import { GetUser } from "../../actions/auth";
import DiscordLogo from "../../assets/discord.png";
import { AddDiscordRole, VerifyDiscordAuth } from "../../actions/api";
import toast from "react-hot-toast";

const Discord = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  let [search, setSearch] = useSearchParams();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetUser()
        .then((res) => {
          authDispatch({
            type: "update",
            payload: {
              user: res.data,
            },
          });
          if (res.data.onboardingStage === 1) {
            navigate("/payment");
          } else if (res.data.onboardingStage === 3) {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const fetchRef = useRef(false);

  useEffect(() => {
    let inView = true;
    if (inView) {
      let code = search.get("code");
      if (code) {
        if (fetchRef.current) return;
        fetchRef.current = true;
        VerifyDiscordAuth({ code: code, redirectUri: window.location.origin + "/discordAuth" })
          .then((res) => {
            GetUser()
              .then((res) => {
                authDispatch({
                  type: "update",
                  payload: {
                    user: res.data,
                  },
                });
                if (res.data.onboardingStage === 3) {
                  AddDiscordRole()
                    .then((res) => {
                      toast.success("Discord account linked successfully");
                      navigate("/");
                    })
                    .catch((err) => {
                      toast.success("Discord account linked successfully");
                      navigate("/");
                    });
                }
              })
              .catch((err) => {
                console.log(err);
                navigate("/");
              });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    navigate("/signIn");
  };

  let discordLink =
    "https://discord.com/api/oauth2/authorize?client_id=1154301085968773181&redirect_uri=https%3A%2F%2Fapp.breadfactory.org%2FdiscordAuth&response_type=code&scope=identify%20guilds%20guilds.join%20email";
  // let discordLink =
  //   "https://discord.com/api/oauth2/authorize?client_id=1154301085968773181&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2FdiscordAuth&response_type=code&scope=identify%20guilds%20guilds.join%20email";
  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start">
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <p
          className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
          onClick={() => logout()}
        >
          Sign Out
        </p>
      </div>
      <div className="flex flex-col justify-center items-center max-w-lg w-full gap-1">
        <p className="text-xl font-semibold">Link your Discord account</p>
        <p className="text-sm text-center text-gray-400">
          In order to access the resources and exclusive content of BreadFactory, you need to link your Discord account.
        </p>
        <div
          className="flex flex-row justify-center items-center gap-2 bg-[#7289da] text-white px-8 py-3 rounded-md mt-10 cursor-pointer hover:bg-[#3e5ccc] duration-150 ease-in-out"
          onClick={() => (window.location.href = discordLink)}
        >
          <img src={DiscordLogo} className="w-6 h-6" alt="Discord Logo" />
          <p className="uppercase font-medium">Sign In With Discord</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Discord;
