import { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import { AddPaymentMethod } from "../actions/payment";
import { useNavigate } from "react-router-dom";

const NewPaymentMethod = ({ close }) => {
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: "if_required",
    });

    if (error) {
      setMessage(error.message);
      toast.error(error.message);
    } else if (setupIntent && setupIntent.status === "succeeded") {
      AddPaymentMethod({ setupIntentId: setupIntent.id })
        .then((res) => {
          toast.success("Payment method added successfully!");
          close();
        })
        .catch((err) => {
          setMessage("Something went wrong, please try again.");
          close();
        });
    } else {
      setMessage("Something went wrong, please try again.");
    }
    setIsProcessing(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className="w-full flex flex-row justify-end items-center bottom-0 gap-2 mt-4">
        <div
          onClick={() => close()}
          className="border focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center border-purple-600/40 hover:border-purple-900 text-purple-600 hover:text-white hover:bg-gradient-to-br hover:from-purple-600/20 hover:to-black duration-150 ease-in-out"
        >
          Cancel
        </div>
        <button
          className="border focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center border-purple-600/40 hover:border-purple-900 text-purple-600 hover:text-white hover:bg-gradient-to-br hover:from-purple-600/20 hover:to-black duration-150 ease-in-out"
          disabled={!stripe || isProcessing}
          type="submit"
        >
          {isProcessing ? "Processing" : stripe ? "Add Payment Method" : "Invalid Session"}
        </button>
      </div>
      <p className="my-3 w-full text-center text-xs text-gray-500">{message}</p>
    </form>
  );
};

export default NewPaymentMethod;
