import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { ResetPasswordAPI } from "../../actions/auth";
import validator from "validator";
import Footer from "../../components/footer";

const ResetPassword = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      let rawToken = searchParams.get("token");
      if (rawToken && rawToken.length > 10) {
        setToken(rawToken);
        setTimeout(() => setLoading(false), 700);
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const submitPwReset = () => {
    if (!validator.isEmail(email)) {
      toast.error("Please enter a valid email address");
    } else if (!validator.isStrongPassword(password)) {
      if (password.length > 0) {
        toast.error("Please enter a stronger password");
      } else {
        toast.error("Please enter a password");
      }
    } else if (password !== confirmPass) {
      toast.error("Passwords do not match");
    } else {
      setLoading(true);
      ResetPasswordAPI({ email, password, token })
        .then((res) => {
          toast.success("Password reset successful. Please login with your new password");
          setTimeout(() => {
            navigate("/signIn", { replace: true });
          }, 700);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start">
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <div className="flex flex-row justify-end items-center gap-4">
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => navigate("/signIn")}
          >
            Sign In
          </p>
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => navigate("/signUp")}
          >
            Create an Account
          </p>
        </div>
      </div>
      <div className="max-w-xl w-full border border-gray-50/20 rounded-xl p-10 px-20 mt-4 flex flex-col justify-start items-center gap-7 py-14">
        <p className="text-xl font-semibold uppercase">Password Reset</p>
        <input
          type="email"
          id="email"
          className="border text-sm rounded-lg block w-full p-4 bg-transparent border-gray-600 placeholder-gray-400 text-white outline-none disabled:cursor-not-allowed disabled:bg-slate-950/25 disabled:text-gray-700"
          placeholder="Email Address"
          required
          disabled={loading}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          id="password"
          className="border text-sm rounded-lg block w-full py-4 px-4 bg-transparent border-gray-600 placeholder-gray-400 text-white outline-none disabled:cursor-not-allowed disabled:bg-slate-950/25 disabled:text-gray-700"
          placeholder="Password"
          required
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
        <input
          type="password"
          id="confirmPassword"
          className="border text-sm rounded-lg block w-full py-4 px-4 bg-transparent border-gray-600 placeholder-gray-400 text-white outline-none disabled:cursor-not-allowed disabled:bg-slate-950/25 disabled:text-gray-700"
          placeholder="Confirm Password"
          required
          onChange={(e) => setConfirmPass(e.target.value)}
          disabled={loading}
        />
        <button
          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white text-white disabled:cursor-not-allowed"
          onClick={() => submitPwReset()}
          disabled={loading}
        >
          <span className="relative px-20 py-2.5 transition-all ease-in duration-75 bg-black group-disabled:bg-[#010206] rounded-md group-hover:bg-opacity-0">
            Reset Password
          </span>
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default ResetPassword;
