import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { SignIn } from "../../actions/auth";
import validator from "validator";
import Footer from "../../components/footer";

const Login = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const submitLogin = () => {
    if (!validator.isEmail(email)) {
      toast.error("Please enter a valid email address");
    } else if (!validator.isStrongPassword(password)) {
      if (password.length > 0) {
        toast.error("Please enter a stronger password");
      } else {
        toast.error("Please enter a password");
      }
    } else {
      setLoading(true);
      SignIn({ email, password })
        .then((res) => {
          let payload = {
            token: res.data.token,
            email: email,
            user: res.data.user,
          };
          localStorage.setItem("auth-token", res.data.token);
          authDispatch({
            type: "valid-login",
            payload: payload,
          });
          toast.success("Sign in successful!");
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 700);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start">
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <p
          className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
          onClick={() => navigate("/signUp")}
        >
          Create an Account
        </p>
      </div>
      <div className="max-w-xl w-full border border-gray-50/20 rounded-xl p-10 px-20 mt-4 flex flex-col justify-start items-center gap-7 py-28">
        <p className="text-xl font-semibold uppercase mb-5">Log into your account</p>
        <input
          type="email"
          id="email"
          className="border text-sm rounded-lg block w-full p-4 bg-transparent border-gray-600 placeholder-gray-400 text-white outline-none disabled:cursor-not-allowed disabled:bg-slate-950/25 disabled:text-gray-700"
          placeholder="Email Address"
          required
          disabled={loading}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          id="password"
          className="border text-sm rounded-lg block w-full p-4 bg-transparent border-gray-600 placeholder-gray-400 text-white outline-none disabled:cursor-not-allowed disabled:bg-slate-950/25 disabled:text-gray-700"
          placeholder="Password"
          required
          disabled={loading}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white text-white disabled:cursor-not-allowed"
          onClick={() => submitLogin()}
          disabled={loading}
        >
          <span className="relative px-20 py-2.5 transition-all ease-in duration-75 bg-black group-disabled:bg-[#010206] rounded-md group-hover:bg-opacity-0">
            Sign In
          </span>
        </button>
        <div className="w-full flex flex-col justify-center items-center font-light text-xs text-gray-500 gap-2">
          <p>
            Forgot your password?{" "}
            <span className="hover:text-white duration-150 cursor-pointer" onClick={() => navigate("/forgotPassword")}>
              Reset it now
            </span>
          </p>
          <p>
            Just starting?{" "}
            <span className="hover:text-white duration-150 cursor-pointer" onClick={() => navigate("/signUp")}>
              Create an account
            </span>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
