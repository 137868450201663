import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import { GetUser } from "../../actions/auth";

const Middleware = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetUser()
        .then((res) => {
          authDispatch({
            type: "update",
            payload: {
              user: res.data,
            },
          });
          if (res.data.onboardingStage === 1) {
            navigate("/payment");
          } else if (res.data.onboardingStage === 2) {
            navigate("/discordAuth");
          } else if (res.data.onboardingStage === 3) {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log(err);
          navigate("/");
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    navigate("/signIn");
  };

  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start">
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <p
          className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
          onClick={() => logout()}
        >
          Sign Out
        </p>
      </div>
      <p>Processing...</p>
      <Footer />
    </div>
  );
};

export default Middleware;
