import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import { BookStack, CloudDownload, Discord, Group, MediaVideoList, SendMail } from "iconoir-react";

const Dashboard = ({ authState, authDispatch }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleMouseMove = (e) => {
      const cards = document.getElementById("cards");
      const cardElements = cards.getElementsByClassName("card");

      for (const card of cardElements) {
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        card.style.setProperty("--mouse-x", `${x}px`);
        card.style.setProperty("--mouse-y", `${y}px`);
      }
    };

    document.getElementById("cards").addEventListener("mousemove", handleMouseMove);

    return () => {
      // document.getElementById("cards").removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    navigate("/signIn");
  };

  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start cursor-pointer" onClick={() => navigate("/")}>
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <p
          className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
          onClick={() => logout()}
        >
          Sign Out
        </p>
      </div>
      <div id="cards">
        <div className="card" onClick={() => window.open("https://discord.gg/TtEfxG57J2", "_blank")}>
          <div className="card-content">
            <div className="card-image flex justify-center items-center">
              <Discord className="h-16 w-auto mix-blend-overlay" />
            </div>
            <div className="card-info-wrapper">
              <div className="card-info">
                <div className="card-info-title">
                  <h3 className="text-lg">Discord</h3>
                  <h4 className="text-xs">Access your resources and benefits in our Discord community</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => navigate("/membership")}>
          <div className="card-content">
            <div className="card-image">
              <Group className="h-16 w-auto mix-blend-overlay" />
            </div>
            <div className="card-info-wrapper">
              <div className="card-info">
                <div className="card-info-title">
                  <h3 className="text-lg">Membership</h3>
                  <h4 className="text-xs">Manage your membership and billing information in one place</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => navigate("/contact")}>
          <div className="card-content">
            <div className="card-image">
              <SendMail className="h-16 w-auto mix-blend-overlay" />
            </div>
            <div className="card-info-wrapper">
              <div className="card-info">
                <i className="fa-duotone fa-blender-phone"></i>
                <div className="card-info-title">
                  <h3 className="text-lg">Contact Us</h3>
                  <h4 className="text-xs">Reach out to our team with any inquiries you may have</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => navigate("/library")}>
          <div className="card-content">
            <div className="card-image">
              <MediaVideoList className="h-16 w-auto mix-blend-overlay" />
            </div>
            <div className="card-info-wrapper">
              <div className="card-info">
                <div className="card-info-title">
                  <h3 className="text-lg">Library</h3>
                  <h4 className="text-xs">Access recordings of previous sessions and seminars</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => navigate("/reading")}>
          <div className="card-content">
            <div className="card-image">
              <BookStack className="h-16 w-auto mix-blend-overlay" />
            </div>
            <div className="card-info-wrapper">
              <div className="card-info">
                <div className="card-info-title">
                  <h3 className="text-lg">Reading</h3>
                  <h4 className="text-xs">Curated list of educational material to get you started</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card" onClick={() => navigate("/content")}>
          <div className="card-content">
            <div className="card-image">
              <CloudDownload className="h-16 w-auto mix-blend-overlay" />
            </div>
            <div className="card-info-wrapper">
              <div className="card-info">
                <div className="card-info-title">
                  <h3 className="text-lg">Content</h3>
                  <h4 className="text-xs">Designs, scripts, cheat-sheets and libraries we use and recommend</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Dashboard;
