import { useEffect, useState } from "react";
import Loader from "./components/loader";
import { GetUser } from "./actions/auth";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./views/auth/login";
import Register from "./views/auth/register";
import ForgotPassword from "./views/auth/forgotPassword";
import CoreLayout from "./layouts/coreLayout";
import { Toaster } from "react-hot-toast";
import PrivacyPolicy from "./views/legal/privacyPolicy";
import TermsAndConditions from "./views/legal/termsAndConditions";
import EULA from "./views/legal/eula";
import ResetPassword from "./views/auth/resetPassword";

const App = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (token) {
      GetUser()
        .then((response) => {
          authDispatch({
            type: "valid-login",
            payload: {
              token: localStorage.getItem("auth-token"),
              role: response.data.role,
              user: response.data,
            },
          });
          setLoading(false);
        })
        .catch((err) => {
          authDispatch({ type: "logged-out" });
          localStorage.removeItem("auth-token");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="w-full h-screen overflow-x-hidden">
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerclassName=""
        containerStyle={{}}
        toastOptions={{
          duration: 5000,
          style: {
            background: "#010101",
            color: "#fff",
            borderRadius: "5px",
            border: "2px solid #0c0f0a",
          },
          className: "maxZIndex",
          success: {
            style: {
              paddingLeft: "15px",
            },
          },
          error: {
            style: {
              paddingLeft: "15px",
            },
          },
        }}
      />
      <Routes>
        <Route path="signIn" element={<Login authDispatch={authDispatch} authState={authState} />} />
        <Route path="signUp" element={<Register authDispatch={authDispatch} authState={authState} />} />
        <Route path="forgotPassword" element={<ForgotPassword authDispatch={authDispatch} authState={authState} />} />
        <Route path="resetPassword" element={<ResetPassword authDispatch={authDispatch} authState={authState} />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy authDispatch={authDispatch} authState={authState} />} />
        <Route path="termsAndConditions" element={<TermsAndConditions authDispatch={authDispatch} authState={authState} />} />
        <Route path="eula" element={<EULA authDispatch={authDispatch} authState={authState} />} />
        <Route path="/*" element={authState.isAuth ? <CoreLayout authState={authState} authDispatch={authDispatch} /> : <Navigate to="/signIn" />} />
      </Routes>
    </div>
  );
};

export default App;
