import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";

const Content = ({ authState, authDispatch }) => {
  const navigate = useNavigate();

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    navigate("/signIn");
  };

  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start cursor-pointer" onClick={() => navigate("/")}>
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <div className="flex flex-row justify-end items-center gap-4">
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => logout()}
          >
            Sign Out
          </p>
        </div>
      </div>
      <div className="w-full max-w-2xl flex flex-col justify-start items-center rounded-xl px-8 py-14">
        <h3 className="text-3xl font-medium">Content</h3>
        <p className="text-xs mt-3">There is no content available as of now</p>
        <p className="text-xs">Please check again later.</p>
        <p className="text-xs mt-4 text-purple-700/50 hover:text-purple-700 duration-150 ease-in-out cursor-pointer" onClick={() => navigate("/")}>
          Go Back
        </p>
      </div>
      <Footer />
    </div>
  );
};
export default Content;
