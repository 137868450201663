import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import { GetPaymentMethods, AddPaymentMethod, GetPaymentIntent } from "../../actions/payment";
import { Dialog, Transition } from "@headlessui/react";
import CreditCardInput from "react-credit-card-input";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import toast from "react-hot-toast";
import NewPaymentMethod from "../../components/newPaymentMethod";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
const PaymentMethods = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [clientSecret, setClientSecret] = useState(null);
  const [open, setOpen] = useState(false);
  const [card, setCard] = useState("");
  const [exp, setExp] = useState("");
  const [ccv, setCcv] = useState("");
  const [zip, setZip] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetPaymentMethods()
        .then((res) => {
          setPaymentMethods(res.data);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    navigate("/signIn");
  };

  const closeNewPayment = () => {
    setOpen(false);
    setCard("");
    setExp("");
    setCcv("");
    setZip("");
    setClientSecret(null);
  };

  const submitNewPayment = () => {
    setLoading(true);
    closeNewPayment();
    GetPaymentMethods()
      .then((res) => {
        setPaymentMethods(res.data);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setupPromise = () => {
    setLoading(true);
    GetPaymentIntent()
      .then((res) => {
        setClientSecret(res.data.client_secret);
        setOpen(true);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      });
  };

  const appearance = {
    theme: "night",
    variables: {
      colorPrimary: "#ffffff",
      colorBackground: "#000000",
      colorText: "#ffffff",
      colorDanger: "#df1b41",
      fontFamily: "Poppins, sans-serif",
      spacingUnit: "4px",
      borderRadius: "4px",
      fontSizeBase: "0.95em",
      fontSmooth: "always",
      colorLogo: "dark",
      spacingGridColumn: "10px",
      spacingGridRow: "11px",
      // See all possible variables below
    },
    rules: {
      ".Input:focus": {
        borderColor: "rgba(147,51,234,0.4)",
        boxShadow: "none",
      },
    },
  };
  const options = {
    clientSecret: clientSecret,
    appearance: appearance,
  };
  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start cursor-pointer" onClick={() => navigate("/")}>
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <div className="flex flex-row justify-end items-center gap-4">
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => logout()}
          >
            Sign Out
          </p>
        </div>
      </div>
      <div className="w-full max-w-2xl flex flex-col justify-start items-center rounded-xl px-8 py-14">
        <h3 className="text-3xl font-medium">Payment Methods</h3>
        {!loading ? (
          <>
            <div className="w-full flex flex-col justify-start items-start gap-4 mt-8 max-h-96 overflow-y-auto">
              {paymentMethods.map((method) => (
                <div className="w-full flex flex-row justify-between items-center px-7 py-6 bg-[#06050F] rounded-lg border border-purple-600/20">
                  <p className="uppercase font-semibold">{method.card.brand} </p>
                  <p>Ending in {method.card.last4}</p>
                </div>
              ))}
            </div>
            <div className="w-full flex flex-row justify-between items-center mt-5">
              <button
                onClick={() => navigate("/membership")}
                className="border focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-10 py-3 text-center border-transparent hover:border-purple-900 text-purple-600/40 hover:text-white hover:bg-gradient-to-br hover:from-purple-600/20 hover:to-black duration-150 ease-in-out"
              >
                Go Back
              </button>
              <button
                onClick={() => setupPromise()}
                className="border focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-10 py-3 text-center border-purple-600/40 hover:border-purple-900 text-purple-600 hover:text-white hover:bg-gradient-to-br hover:from-purple-600/20 hover:to-black duration-150 ease-in-out"
              >
                Add Payment Method
              </button>
            </div>
          </>
        ) : (
          <div className="mt-8">
            <p className="text-xs font-light">Loading...</p>
          </div>
        )}
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/90 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#06050F] border border-purple-600/20 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:px-6 sm:pt-4 sm:pb-3">
                    <div>
                      <div className="mt-2 text-center sm:mt-2">
                        <Dialog.Title as="h3" className="font-semibold leading-6 text-gray-400 mb-6 text-xl">
                          Add a new payment method
                        </Dialog.Title>
                        {!loading && (
                          <Elements stripe={stripePromise} options={options} appearance={appearance}>
                            <NewPaymentMethod close={() => submitNewPayment()} />
                          </Elements>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
      <Footer />
    </div>
  );
};
export default PaymentMethods;
