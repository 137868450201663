import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, createBrowserRouter } from "react-router-dom";
import { AuthProvider, AuthConsumer } from "./context";

const root = ReactDOM.createRoot(document.getElementById("breadfactory"));
root.render(
  <StrictMode>
    <AuthProvider>
      <AuthConsumer>
        {(authState) => (
          <BrowserRouter>
            <App authState={authState} authDispatch={authState.authDispatch} />
          </BrowserRouter>
        )}
      </AuthConsumer>
    </AuthProvider>
  </StrictMode>
);
