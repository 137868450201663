import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import { GetMembershipStatus } from "../../actions/payment";
import dayjs from "dayjs";
import { Dialog, Transition } from "@headlessui/react";

const ManageMembership = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [membership, setMembership] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetMembershipStatus()
        .then((res) => {
          setMembership(res.data.subscription);
          setPaymentMethod(res.data.paymentMethod);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    navigate("/signIn");
  };

  const statuses = {
    incomplete: "Incomplete",
    incomplete_expired: "Expired",
    trialing: "Trialing",
    active: "Active",
    past_due: "Past Due",
    canceled: "Cancelled",
    unpaid: "Unpaid",
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  let today = dayjs();

  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start cursor-pointer" onClick={() => navigate("/")}>
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <div className="flex flex-row justify-end items-center gap-4">
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => logout()}
          >
            Sign Out
          </p>
        </div>
      </div>
      <div className="w-full max-w-2xl flex flex-col justify-start items-center rounded-xl px-8 py-14">
        <h3 className="text-3xl font-medium">Manage Membership</h3>
        {!loading && (
          <div className="flex flex-col justify-start items-start w-full mt-8">
            <div className="mt-6 border-t border-white/10 w-full">
              <dl className="divide-y divide-white/10">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-white">Membership Status</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0 text-right">{statuses[membership.status]}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-white">Member Since</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0 text-right">
                    {dayjs(membership.start_date * 1000).format("MM/DD/YYYY")}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-white">Next Billing Cycle</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0 text-right">
                    {dayjs(membership.current_period_end * 1000).format("MM/DD/YYYY")}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-white">Membership Price</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0 text-right">{formatter.format(membership.plan.amount / 100)}</dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-white">Default Payment Method</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0 text-right">
                    <span className="uppercase text-gray-400">{paymentMethod.card.brand}</span> ending in {paymentMethod.card.last4}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-white">Manage Membership</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0 text-right">
                    <button
                      onClick={() => setOpen(true)}
                      className="focus:outline-none font-medium rounded-lg text-sm text-center text-purple-600 hover:text-white duration-150 ease-in-out ring-0 focus:ring-0 active:ring-0 border-0"
                    >
                      Cancel Membership
                    </button>
                  </dd>
                </div>
              </dl>
            </div>
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/90 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#06050F] border border-purple-600/20 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                        <div>
                          <div className="mt-2 text-center sm:mt-2">
                            <Dialog.Title as="h3" className="font-semibold leading-6 text-gray-400 mb-3 text-xl">
                              Cancel Membership
                            </Dialog.Title>
                            <p className="text-gray-600 text-sm font-light">
                              If you wish to cancel your membership, please email us at{" "}
                              <span className="cursor-pointer text-white font-light" onClick={() => window.open("mailto:billing@breadfactory.org", "_blank")}>
                                billing@breadfactory.org
                              </span>
                            </p>
                            <div className="w-full flex flex-row justify-center items-center mb-2 mt-5">
                              <button
                                onClick={() => setOpen(false)}
                                className="focus:outline-none font-medium rounded-lg text-sm text-center  text-purple-600 hover:text-white duration-150 ease-in-out ring-0"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        )}
        <p className="text-xs mt-4 text-purple-700/50 hover:text-purple-700 duration-150 ease-in-out cursor-pointer" onClick={() => navigate("/membership")}>
          Go Back
        </p>
      </div>
      <Footer />
    </div>
  );
};
export default ManageMembership;
