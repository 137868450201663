import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import axios from "axios";
import toast from "react-hot-toast";
import { SubmitContactForm } from "../../actions/api";
import dayjs from "dayjs";

const ContactUs = ({ authState, authDispatch }) => {
  const [name, setName] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");

  let nameRef = useRef();
  let reasonRef = useRef();
  let messageRef = useRef();

  const navigate = useNavigate();

  const logout = () => {
    authDispatch({ type: "logged-out" });
    localStorage.removeItem("auth-token");
    navigate("/signIn");
  };

  const getIPData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
  };

  const submitForm = async () => {
    if (name === "") {
      toast.error("Please enter your name");
    } else if (reason.length < 5) {
      toast.error("Please enter a valid reason");
    } else if (message.length < 10) {
      toast.error("Please enter a valid message");
    } else {
      let ip = await getIPData();
      let toSend = {
        from: name,
        subject: reason,
        message: message,
        sentAt: dayjs().toJSON(),
        ipAddress: ip,
      };
      SubmitContactForm(toSend)
        .then((res) => {
          if (messageRef.current) messageRef.current.value = "";
          if (reasonRef.current) reasonRef.current.value = "";
          if (nameRef.current) nameRef.current.value = "";
          toast.success("Your message has been sent. if necessary, our team will be in touch with you shortly");
        })
        .catch((err) => {
          toast.error("Something went wrong, please try again later");
        });
    }
  };

  return (
    <div className="w-full h-screen bg-black flex flex-col justify-between items-center text-white bg-gradient-to-br from-violet-900/20 via-black to-violet-900/20">
      <div className="w-full max-w-[1440px] flex flex-row justify-between items-center px-4 py-4">
        <div className="flex flex-col justify-center items-start cursor-pointer" onClick={() => navigate("/")}>
          <p className="font-bold text-xl italic">BREADFACTORY</p>
          <p className="text-xs font-light tracking-widest uppercase">Get rich or die trying</p>
        </div>
        <div className="flex flex-row justify-end items-center gap-4">
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => navigate("/")}
          >
            Home
          </p>
          <p
            className="text-xs uppercase tracking-wide underline decoration-transparent hover:decoration-white underline-offset-4 cursor-pointer duration-150"
            onClick={() => logout()}
          >
            Sign Out
          </p>
        </div>
      </div>
      <div className="w-full max-w-2xl flex flex-col justify-start items-center border border-purple-700/20 rounded-xl px-8 py-14 gap-5">
        <h3 className="text-lg font-light">Tell us how we can help</h3>
        <input
          type="text"
          id="text"
          className="border text-sm rounded-lg block w-full px-4 py-3 bg-transparent border-purple-600/20 placeholder-gray-400 text-white outline-none disabled:cursor-not-allowed disabled:bg-slate-950/25 disabled:text-gray-700"
          placeholder="Your full name"
          required
          onChange={(e) => setName(e.target.value)}
          ref={nameRef}
        />
        <input
          type="text"
          id="text"
          className="border text-sm rounded-lg block w-full px-4 py-3 bg-transparent border-purple-600/20 placeholder-gray-400 text-white outline-none disabled:cursor-not-allowed disabled:bg-slate-950/25 disabled:text-gray-700"
          placeholder="Reason for contacting us"
          required
          onChange={(e) => setReason(e.target.value)}
          ref={reasonRef}
        />
        <textarea
          rows={5}
          className="resize-none border text-sm rounded-lg block w-full px-4 py-3 bg-transparent border-purple-600/20 placeholder-gray-400 text-white outline-none disabled:cursor-not-allowed disabled:bg-slate-950/25 disabled:text-gray-700"
          placeholder="Reason for contacting us"
          required
          onChange={(e) => setMessage(e.target.value)}
          ref={messageRef}
        />
        <div className="w-full flex flex-row justify-between items-center">
          <p
            className="text-xs font-light text-purple-600/50 hover:text-purple-600 duration-150 ease-in-out cursor-pointer"
            onClick={() => window.open("mailto:contact@breadfactory.org", "_blank")}
          >
            You can also reach us at contact@breadfactory.org
          </p>
          <button
            onClick={() => submitForm()}
            className="border focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center border-purple-600/40 hover:border-purple-900 text-purple-600 hover:text-white hover:bg-gradient-to-br hover:from-purple-600/20 hover:to-black duration-150 ease-in-out"
          >
            Send
          </button>
        </div>
        <p className="text-xs mt-4 text-purple-700/50 hover:text-purple-700 duration-150 ease-in-out cursor-pointer" onClick={() => navigate("/")}>
          Go Back
        </p>
      </div>
      <Footer />
    </div>
  );
};
export default ContactUs;
