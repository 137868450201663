import axiosInstance from "./axios";

export const GetPaymentIntent = () => {
  return axiosInstance.get("/payments/intent");
};

export const CheckIntent = (data) => {
  return axiosInstance.post("/payments/checkIntent", data);
};

export const GetPaymentMethods = () => {
  return axiosInstance.get("/payments/payments");
};

export const AddPaymentMethod = (data) => {
  return axiosInstance.post("/payments/payment", data);
};

export const GetMembershipStatus = () => {
  return axiosInstance.get("/payments/membership");
};
