import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full max-w-[1440px] flex justify-center items-center gap-4 py-4 text-gray-700">
      <p className="text-xs hover:text-white duration-150 cursor-pointer" onClick={() => navigate("/privacyPolicy")}>
        Privacy Policy
      </p>
      <p className="text-xs hover:text-white duration-150 cursor-pointer" onClick={() => navigate("/termsAndConditions")}>
        Terms & Conditions
      </p>
      <p className="text-xs hover:text-white duration-150 cursor-pointer" onClick={() => navigate("/eula")}>
        EULA
      </p>
    </div>
  );
};

export default Footer;
